<template>
  <div class="monitor-extensions">
    <div class="d-flex bg-white p-3 align--center datatable-controls">
      <items-per-page
        :options="perPageOptions"
        :label="$t('extensions.per-page')"
        :defaultPerPage="10"
        @items-per-page="onPerPageChange"
      />
    </div>

    <data-table
      ref="monitorExtensions"
      :data="monitorExtensions"
      :fields="translatedTableFields"
      :isLoading="monitorExtensionsLoading"
      :pagination="pagination"
    >
      <template slot="status" slot-scope="props">
        <MonitorStatus :rowData="props.rowData" />
      </template>
      <template slot="on_call" slot-scope="props">
        <MonitorOnCall :rowData="props.rowData" />
      </template>
    </data-table>
  </div>
</template>

<script>
import Vue from 'vue'
import DataTable from '../../../elements/DataTable.vue'
import MonitorStatus from './components/monitor-status.vue'
import MonitorOnCall from './components/monitor-onCall.vue'
import ExtensionService from '../../../services/extension.service'
import ItemsPerPage from '../../../vuestic-theme/vuestic-components/vuestic-datatable/datatable-components/ItemsPerPage'

Vue.component('monitor-status', MonitorStatus)
Vue.component('monitor-on-call', MonitorOnCall)

let interval

export default {
  components: {
    DataTable,
    MonitorStatus,
    MonitorOnCall,
    ItemsPerPage
  },
  data() {
    return {
      fields: [
        {
          name: '__component:monitor-status',
          title: 'extensions-monitor.table.status'
        },
        {
          name: '__component:monitor-on-call',
          title: 'extensions-monitor.table.on_call'
        },
        {
          name: 'name',
          title: 'extensions-monitor.table.name'
        },
        { name: 'ext', title: 'extensions-monitor.table.ext' },
        { name: 'ip', title: 'extensions-monitor.table.ip' }
      ],
      monitorExtensions: [],
      monitorExtensionsLoading: false,
      pagination: {
        perPage: 10
      },
      perPageOptions: [
        {
          value: 10
        },
        {
          value: 25
        },
        {
          value: 100
        }
      ]
    }
  },
  computed: {
    translatedTableFields() {
      return this.fields.map(field => ({
        ...field,
        title: this.$t(field.title)
      }))
    }
  },
  methods: {
    async getMonitorExtensions(silent) {
      try {
        if (!silent) this.monitorExtensionsLoading = true
        const data = await ExtensionService.getMonitorExtensions()
        this.monitorExtensions = data
      } catch (error) {
      } finally {
        if (!silent) this.monitorExtensionsLoading = false
      }
    },
    onPerPageChange(val) {
      this.pagination.perPage = val
      Vue.nextTick(() => this.$refs.monitorExtensions.refresh())
    }
  },
  mounted() {
    this.getMonitorExtensions()

    interval = setInterval(() => {
      this.getMonitorExtensions(true)
    }, 60000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(interval)
    next()
  }
}
</script>
<style lang=""></style>
