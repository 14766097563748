<template>
  <span :class="'font-bold ' + statusColor">
    <b>
      {{ $t(`extensions-monitor.table.onCall.${rowData.on_call || 'false'}`) }}
    </b>
  </span>
</template>

<script>
export default {
  name: 'extensionsStatus',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusColor() {
      switch (this.rowData.on_call) {
        case true:
          return 'text-success'
        default:
          return 'text-primary'
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
